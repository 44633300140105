<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card card-custom card-sticky">
          <!-- Notification container -->
          <notifications group="notification" position="top right" />

          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">{{ $t('PROMO_BOX.EDIT_PROMO_BOX') }}</h3>
            </div>
            <div class="card-toolbar">
              <router-link
                to="/loyalty/promo-boxes/list"
                class="btn btn-light-primary font-weight-bolder mr-2"
              >
                <i class="ki ki-long-arrow-back icon-sm"></i>
                {{ $t('PROMO_BOX.ALL_PROMO_BOXES') }}
              </router-link>
              <div class="js-submit-options btn-group">
                <button
                  @click="updatePromoBox"
                  type="button"
                  class="btn btn-primary font-weight-bolder"
                >
                  <i class="ki ki-check icon-sm"></i>
                  {{ $t('PROMO_BOX.UPDATE_PROMO_BOX') }}
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label for="promoBoxTitle">{{ $t('PROMO_BOX.TITLE') }}</label>
              <input
                v-model="promoBox.title"
                id="promoBoxTitle"
                class="form-control form-control-solid"
                :placeholder="$t('PROMO_BOX.TITLE')"
              />
            </div>
            <div class="form-group">
              <label for="promoBoxBackgroundColor">{{ $t('PROMO_BOX.BACKGROUND_COLOR') }}</label>
              <select
                v-model="promoBox.backgroundColor"
                id="promoBoxBackgroundColor"
                class="form-control form-control-solid"
              >
                <option value="" disabled>{{ $t('PROMO_BOX.SELECT_BACKGROUND_COLOR') }}</option>
                <option v-for="option in colorOptions" :key="option.value" :value="option.value">
                  {{ option.text }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="promoBoxTextColor">{{ $t('PROMO_BOX.TEXT_COLOR') }}</label>
              <select
                v-model="promoBox.textColor"
                id="promoBoxTextColor"
                class="form-control form-control-solid"
              >
                <option value="" disabled>{{ $t('PROMO_BOX.SELECT_TEXT_COLOR') }}</option>
                <option v-for="option in colorOptions" :key="option.value" :value="option.value">
                  {{ option.text }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label>{{ $t('PROMO_BOX.INCLUDE_ACTION_BUTTON') }}</label>
              <div>
                <input type="radio" id="actionButtonYes" value="true" v-model="isActionButton">
                <label for="actionButtonYes">{{ $t('PROMO_BOX.YES') }}</label>
                <input type="radio" id="actionButtonNo" value="false" v-model="isActionButton">
                <label for="actionButtonNo">{{ $t('PROMO_BOX.NO') }}</label>
              </div>
            </div>
            <div class="form-group" v-if="isActionButton === 'true'">
              <label for="promoBoxActionButtonType">{{ $t('PROMO_BOX.ACTION_BUTTON') }}</label>
              <select v-model="promoBox.actionButtonType" id="promoBoxActionButtonType" class="form-control form-control-solid">
                <option value="" disabled>{{ $t('PROMO_BOX.SELECT_ACTION_BUTTON') }}</option>
                <option v-for="option in actionButtonOptions" :key="option.value" :value="option.value">
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import axios from 'axios';
import Vue from 'vue';

export default {
  name: 'LoyaltyEditPromoBox',
  data() {
    return {
      promoBox: {
        backgroundColor: null,
        textColor: null,
        title: null,
        actionButtonType: null,
      },
      isActionButton: 'false',
      actionButtonOptions: [
        { value: 'Profile', text: 'Profile' },
      ],
      colorOptions: [
        { value: 'aliceblue', text: 'Alice Blue' },
        { value: 'antiquewhite', text: 'Antique White' },
        { value: 'aqua', text: 'Aqua' },
        { value: 'aquamarine', text: 'Aquamarine' },
        { value: 'azure', text: 'Azure' },
        { value: 'beige', text: 'Beige' },
        { value: 'bisque', text: 'Bisque' },
        { value: 'black', text: 'Black' },
        { value: 'blanchedalmond', text: 'Blanched Almond' },
        { value: 'blue', text: 'Blue' },
        { value: 'blueviolet', text: 'Blue Violet' },
        { value: 'brown', text: 'Brown' },
        { value: 'burlywood', text: 'Burly Wood' },
        { value: 'cadetblue', text: 'Cadet Blue' },
        { value: 'chartreuse', text: 'Chartreuse' },
        { value: 'chocolate', text: 'Chocolate' },
        { value: 'coral', text: 'Coral' },
        { value: 'cornflowerblue', text: 'Cornflower Blue' },
        { value: 'cornsilk', text: 'Cornsilk' },
        { value: 'crimson', text: 'Crimson' },
        { value: 'cyan', text: 'Cyan' },
        { value: 'darkblue', text: 'Dark Blue' },
        { value: 'darkcyan', text: 'Dark Cyan' },
        { value: 'darkgoldenrod', text: 'Dark GoldenRod' },
        { value: 'darkgray', text: 'Dark Gray' },
        { value: 'darkgreen', text: 'Dark Green' },
        { value: 'darkgrey', text: 'Dark Grey' },
        { value: 'darkkhaki', text: 'Dark Khaki' },
        { value: 'darkmagenta', text: 'Dark Magenta' },
        { value: 'darkolivegreen', text: 'Dark Olive Green' },
        { value: 'darkorange', text: 'Dark Orange' },
        { value: 'darkorchid', text: 'Dark Orchid' },
        { value: 'darkred', text: 'Dark Red' },
        { value: 'darksalmon', text: 'Dark Salmon' },
        { value: 'darkseagreen', text: 'Dark Sea Green' },
        { value: 'darkslateblue', text: 'Dark Slate Blue' },
        { value: 'darkslategray', text: 'Dark Slate Gray' },
        { value: 'darkslategrey', text: 'Dark Slate Grey' },
        { value: 'darkturquoise', text: 'Dark Turquoise' },
        { value: 'darkviolet', text: 'Dark Violet' },
        { value: 'deeppink', text: 'Deep Pink' },
        { value: 'deepskyblue', text: 'Deep Sky Blue' },
        { value: 'dimgray', text: 'Dim Gray' },
        { value: 'dimgrey', text: 'Dim Grey' },
        { value: 'dodgerblue', text: 'Dodger Blue' },
        { value: 'firebrick', text: 'Fire Brick' },
        { value: 'floralwhite', text: 'Floral White' },
        { value: 'forestgreen', text: 'Forest Green' },
        { value: 'fuchsia', text: 'Fuchsia' },
        { value: 'gainsboro', text: 'Gainsboro' },
        { value: 'ghostwhite', text: 'Ghost White' },
        { value: 'gold', text: 'Gold' },
        { value: 'goldenrod', text: 'Golden Rod' },
        { value: 'gray', text: 'Gray' },
        { value: 'green', text: 'Green' },
        { value: 'greenyellow', text: 'Green Yellow' },
        { value: 'grey', text: 'Grey' },
        { value: 'honeydew', text: 'Honey Dew' },
        { value: 'hotpink', text: 'Hot Pink' },
        { value: 'indianred', text: 'Indian Red' },
        { value: 'indigo', text: 'Indigo' },
        { value: 'ivory', text: 'Ivory' },
        { value: 'khaki', text: 'Khaki' },
        { value: 'lavender', text: 'Lavender' },
        { value: 'lavenderblush', text: 'Lavender Blush' },
        { value: 'lawngreen', text: 'Lawn Green' },
        { value: 'lemonchiffon', text: 'Lemon Chiffon' },
        { value: 'lightblue', text: 'Light Blue' },
        { value: 'lightcoral', text: 'Light Coral' },
        { value: 'lightcyan', text: 'Light Cyan' },
        { value: 'lightgoldenrodyellow', text: 'Light GoldenRod Yellow' },
        { value: 'lightgray', text: 'Light Gray' },
        { value: 'lightgreen', text: 'Light Green' },
        { value: 'lightgrey', text: 'Light Grey' },
        { value: 'lightpink', text: 'Light Pink' },
        { value: 'lightsalmon', text: 'Light Salmon' },
        { value: 'lightseagreen', text: 'Light Sea Green' },
        { value: 'lightskyblue', text: 'Light Sky Blue' },
        { value: 'lightslategray', text: 'Light Slate Gray' },
        { value: 'lightslategrey', text: 'Light Slate Grey' },
        { value: 'lightsteelblue', text: 'Light Steel Blue' },
        { value: 'lightyellow', text: 'Light Yellow' },
        { value: 'lime', text: 'Lime' },
        { value: 'limegreen', text: 'Lime Green' },
        { value: 'linen', text: 'Linen' },
        { value: 'magenta', text: 'Magenta' },
        { value: 'maroon', text: 'Maroon' },
        { value: 'mediumaquamarine', text: 'Medium Aqua Marine' },
        { value: 'mediumblue', text: 'Medium Blue' },
        { value: 'mediumorchid', text: 'Medium Orchid' },
        { value: 'mediumpurple', text: 'Medium Purple' },
        { value: 'mediumseagreen', text: 'Medium Sea Green' },
        { value: 'mediumslateblue', text: 'Medium Slate Blue' },
        { value: 'mediumspringgreen', text: 'Medium Spring Green' },
        { value: 'mediumturquoise', text: 'Medium Turquoise' },
        { value: 'mediumvioletred', text: 'Medium Violet Red' },
        { value: 'midnightblue', text: 'Midnight Blue' },
        { value: 'mintcream', text: 'Mint Cream' },
        { value: 'mistyrose', text: 'Misty Rose' },
        { value: 'moccasin', text: 'Moccasin' },
        { value: 'navajowhite', text: 'Navajo White' },
        { value: 'navy', text: 'Navy' },
        { value: 'oldlace', text: 'Old Lace' },
        { value: 'olive', text: 'Olive' },
        { value: 'olivedrab', text: 'Olive Drab' },
        { value: 'orange', text: 'Orange' },
        { value: 'orangered', text: 'Orange Red' },
        { value: 'orchid', text: 'Orchid' },
        { value: 'palegoldenrod', text: 'Pale GoldenRod' },
        { value: 'palegreen', text: 'Pale Green' },
        { value: 'paleturquoise', text: 'Pale Turquoise' },
        { value: 'palevioletred', text: 'Pale Violet Red' },
        { value: 'papayawhip', text: 'Papaya Whip' },
        { value: 'peachpuff', text: 'Peach Puff' },
        { value: 'peru', text: 'Peru' },
        { value: 'pink', text: 'Pink' },
        { value: 'plum', text: 'Plum' },
        { value: 'powderblue', text: 'Powder Blue' },
        { value: 'purple', text: 'Purple' },
        { value: 'rebeccapurple', text: 'Rebecca Purple' },
        { value: 'red', text: 'Red' },
        { value: 'rosybrown', text: 'Rosy Brown' },
        { value: 'royalblue', text: 'Royal Blue' },
        { value: 'saddlebrown', text: 'Saddle Brown' },
        { value: 'salmon', text: 'Salmon' },
        { value: 'sandybrown', text: 'Sandy Brown' },
        { value: 'seagreen', text: 'Sea Green' },
        { value: 'seashell', text: 'Sea Shell' },
        { value: 'sienna', text: 'Sienna' },
        { value: 'silver', text: 'Silver' },
        { value: 'skyblue', text: 'Sky Blue' },
        { value: 'slateblue', text: 'Slate Blue' },
        { value: 'slategray', text: 'Slate Gray' },
        { value: 'slategrey', text: 'Slate Grey' },
        { value: 'snow', text: 'Snow' },
        { value: 'springgreen', text: 'Spring Green' },
        { value: 'steelblue', text: 'Steel Blue' },
        { value: 'tan', text: 'Tan' },
        { value: 'teal', text: 'Teal' },
        { value: 'thistle', text: 'Thistle' },
        { value: 'tomato', text: 'Tomato' },
        { value: 'turquoise', text: 'Turquoise' },
        { value: 'violet', text: 'Violet' },
        { value: 'wheat', text: 'Wheat' },
        { value: 'white', text: 'White' },
        { value: 'whitesmoke', text: 'White Smoke' },
        { value: 'yellow', text: 'Yellow' },
        { value: 'yellowgreen', text: 'Yellow Green' }
      ]
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Loyalty Promo Boxes', route: '' },
    ]);
    this.loadPromoBox();
  },
  methods: {
    loadPromoBox() {
      const promoBoxId = this.$route.params.id;
      axios
        .get(`${process.env.VUE_APP_LOYALTY_API_ENDPOINT_ROUTE_API_URL}/api/v1/promo_boxes/${promoBoxId}`)
        .then((response) => {
          this.promoBox = response.data;
          this.isActionButton = this.promoBox.actionButtonType ? 'true' : 'false';
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updatePromoBox() {
      const promoBoxId = this.$route.params.id;
      if (this.isActionButton === 'false') {
        this.promoBox.actionButtonType = null;
      }
      axios
        .put(`${process.env.VUE_APP_LOYALTY_API_ENDPOINT_ROUTE_API_URL}/api/v1/promo_boxes/${promoBoxId}`, this.promoBox)
        .then(() => {
          // Show success notification
          Vue.notify({
            group: 'notification',
            type: 'success',
            title: 'Success',
            text: 'Promo Box has been updated successfully.'
          });

          // Redirect after short delay
          setTimeout(() => {
            this.$router.push({ name: 'loyalty-promo-boxes-list' });
          }, 200);
        })
        .catch((error) => {
          // Show error notification
          Vue.notify({
            group: 'notification',
            type: 'error',
            title: 'Error',
            text: 'An error occurred while updating the Promo Box.'
          });

          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';

.form-control {
  &--owner-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }
    .vs__dropdown-toggle {
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: flex;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__no-options {
    }
    .vs__spinner {
    }
    .vs__deselect {
    }
    .vs__active {
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
}
</style>
